import Loader from '@shared/components/Loader';
import {RootState} from '@store';
import {ReactNode, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';

import Header from '@shared/components/Header';
import {PageContainer} from '../App.style';
import ROUTES from './routes';

interface IRoutesProps {
  children: ReactNode;
  isAuthenticated: boolean;
}

function PrivateRoute(props: IRoutesProps) {
  const {children, isAuthenticated} = props;
  /**
  if (!isAuthenticated && !window.location.pathname.includes('login')) {
    // if the resume loan notification template then store the its entire url else only store pathName
    if (window.location.href.includes(PATH.RESUME_TEMPLATE_PATH)) {
      sessionStorage.setItem('lastPageUrl', window.location.href);
    } else if (window.location.href.includes('redirect')) {
      sessionStorage.setItem('lastPageUrl', window.location.pathname);
    } else {
      sessionStorage.setItem('lastPageUrl', '');
    }
  }
  */
  return isAuthenticated ? (
    (children as JSX.Element)
  ) : (
    <Navigate to='/admin/login' />
  );
}

function PublicRoute(props: IRoutesProps) {
  const {children, isAuthenticated} = props;
  return isAuthenticated ? <Navigate to='/admin' /> : (children as JSX.Element);
}

function AppRoutes() {
  const {isLoggedIn} = useSelector((state: RootState) => state.login);
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {ROUTES.map((route) =>
          route.isPrivate ? (
            <Route
              key={route.name}
              path={`/admin${route.path}`}
              element={
                <PrivateRoute isAuthenticated={isLoggedIn}>
                  <PageContainer>
                    {/* <IdleDetector /> */}
                    <Header />

                    <route.component />
                  </PageContainer>
                </PrivateRoute>
              }
            />
          ) : (
            <Route
              key={route.name}
              path={`/admin${route.path}`}
              element={
                route.exact ? (
                  <PublicRoute isAuthenticated={isLoggedIn}>
                    <route.component />
                  </PublicRoute>
                ) : (
                  <route.component />
                )
              }
            />
          )
        )}
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
