import {Box} from '@mui/material';
import styled from 'styled-components';

const AppContainer = styled.div`
  width: 100%;
  background-color: #fafafa;
`;

export const MainWrapper = styled(Box)`
  /* flex: 1;
  margin-left: calc(235px + 100px);

  @media (max-width: 1120px) {
    padding: 0;
    margin-left: calc(84px + 100px);
    margin-right: 0;
  }

  @media (max-width: 850px) {
    padding: 0;
    margin-left: calc(50px + 100px);
    margin-right: 0;
  } */

  //? info this is always bellow of all css
  @media (max-width: 480px) {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const PageContainer = styled(Box)`
  /* display: flex;
  justify-content: center; */
  width: 100%;

  .dashboard-container {
    @media (max-width: 480px) {
      padding: 0 20px;
    }
  }
  header {
    width: 100%;
  }
`;
export default AppContainer;
