import {AppBar, Button, Toolbar} from '@mui/material';
import styled from 'styled-components';
import seedflexLogo from '@assets/icons/seedflex-header.svg';
import {dispatch} from '@store';
import {loginActions} from '@pages/Login/auth/store/loginReducer';

const StyledAppBar = styled(AppBar)`
  && {
    background-color: #fafafa;
    box-shadow: none;
    border-bottom: 1px solid #d9d9d9;
    button {
      width: 100px;
      background-color: #4650ef;
      color: #ffffff;
    }
    .MuiToolbar-root {
      justify-content: space-between;
    }
  }
`;

function Header() {
  const onLogout = () => {
    dispatch(loginActions.logout());
  };
  return (
    <StyledAppBar position='static'>
      <Toolbar>
        <img src={seedflexLogo} alt='Logo' />
        <Button onClick={onLogout}>Logout</Button>
      </Toolbar>
    </StyledAppBar>
  );
}

export default Header;
