import {LoaderWrapper, StyledLoader} from './index.style';

function Loader() {
  return (
    <LoaderWrapper>
      <StyledLoader size={64} />
    </LoaderWrapper>
  );
}

export default Loader;
