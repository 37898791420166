import {lazy} from 'react';

const ROUTES = [
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('../pages/Login')),
    name: 'LoginPage',
    isPrivate: false,
  },
  {
    path: '/',
    exact: true,
    component: lazy(() => import('../pages/Dashboard')),
    name: 'DashboardPage',
    isPrivate: true,
  },
  {
    path: '/view_download_ctp_applications',
    exact: true,
    component: lazy(() => import('../pages/CtpView')),
    name: 'CtpViewPage',
    isPrivate: true,
  },
  {
    path: '/view_download_disbursement_ready_applications',
    exact: true,
    component: lazy(() => import('../pages/ViewDisbursement')),
    name: 'ViewDisbursement',
    isPrivate: true,
  },
  {
    path: '/upload_ctp_application_result',
    exact: true,
    component: lazy(() => import('../pages/UploadCtp')),
    name: 'UploadCtp',
    isPrivate: true,
  },
  {
    path: '/upload_disbursement_result',
    exact: true,
    component: lazy(() => import('../pages/UploadDisbursement')),
    name: 'UploadDisbursement',
    isPrivate: true,
  },
  {
    path: '/view_kyc_applications',
    exact: true,
    component: lazy(() => import('../pages/KycView')),
    name: 'KycView',
    isPrivate: true,
  },
  {
    path: '/view_kyc_applications/:id',
    exact: true,
    component: lazy(() => import('../pages/ApplicationDetails')),
    name: 'ApplicationDetails',
    isPrivate: true,
  },
  {
    path: '/upload_merchant_data',
    exact: true,
    component: lazy(() => import('../pages/UploadMerchantData')),
    name: 'UploadMerchantData',
    isPrivate: true,
  },
  {
    path: '/view_start_repayment_instruction',
    exact: true,
    component: lazy(() => import('../pages/RepaymentInstruction')),
    name: 'RepaymentInstruction',
    isPrivate: true,
  },
  {
    path: '/view_stop_repayment_instruction',
    exact: true,
    component: lazy(() => import('../pages/StopRepaymentInstruction')),
    name: 'StopRepaymentInstruction',
    isPrivate: true,
  },
  {
    path: '/upload_repayment_transactions',
    exact: true,
    component: lazy(() => import('../pages/UploadRepaymentTransactions')),
    name: 'UploadRepaymentTransactions',
    isPrivate: true,
  },
  {
    path: '/view_merchant_limits',
    exact: true,
    component: lazy(() => import('../pages/ViewMerchantLimits')),
    name: 'ViewMerchantLimits',
    isPrivate: true,
  },
  {
    path: '/view_refund_list',
    exact: true,
    component: lazy(() => import('../pages/ViewRefundList')),
    name: 'ViewRefundList',
    isPrivate: true,
  },
  {
    path: '/upload_enrollment',
    exact: true,
    component: lazy(() => import('../pages/UploadEnrollment')),
    name: 'UploadEnrollment',
    isPrivate: true,
  },
];

export default ROUTES;
